import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Layout from '~/components/Layout/Layout';
import Metadata from '~/components/Metadata';
import { ArticleListPage } from '~/components/ArticleListPage/ArticleListPage';
import { ContentfulPageSection, Protocol } from '~/types/types';

const GuidedProtocols: FC<GuidedProtocolsProps> = ({ data }) => {
  const protocols = data.protocols.edges.map(({ node }) => node);
  return (
    <Layout>
      <Metadata title={data.page?.name} />
      <ArticleListPage
        hero={data.page.hero}
        articles={protocols}
        sections={data.page.sections}
      />
    </Layout>
  );
};

interface GuidedProtocolsProps {
  data: GuidedProtocolsPageData;
}

export interface GuidedProtocolsPageData {
  protocols: {
    edges: {
      node: Protocol;
    }[];
  };
  page: {
    name: string;
    hero: ContentfulPageSection;
    sections: ContentfulPageSection[];
  };
}

export const query = graphql`
  query GuidedProtocolsPage($locale: String) {
    protocols: allContentfulGuidedProtocol {
      edges {
        node {
          ...ProtocolCardFragment
        }
      }
    }
    page: contentfulPage(
      slug: { eq: "guided-protocols" }
      node_locale: { eq: $locale }
    ) {
      name
      hero {
        ...ContentSectionFragment
      }
      sections {
        ...ContentSectionFragment
      }
    }
  }
`;

export default GuidedProtocols;
