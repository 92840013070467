import React from 'react';
import { Section } from '~/styles/Section.styled';
import { Wrapper } from '~/styles/Wrapper.styled';
import { PageSection, ComponentMapExtraData } from '../PageSection/PageSection';
import ShowOnScroll from '~/components/Generic/ShowOnScroll/ShowOnScroll';
import { ArticleCard } from '~/components/ArticleCard/ArticleCard';
import { ProtocolCard } from '~/components/ProtocolCard/ProtocolCard';
import { StyledArticleListPage } from './ArticleListPage.style';

import { Article, ContentfulPageSection, Protocol } from '~/types/types';

const articleListPageSectionsDefaultData: ComponentMapExtraData = {};

export const ArticleListPage: React.FC<ArticleListPageProps> = ({
  hero,
  articles,
  sections,
}) => {
  return (
    <StyledArticleListPage>
      <Section>
        <Wrapper width="wide">
          <ShowOnScroll>
            <div
              className="article-list--text-banner"
              dangerouslySetInnerHTML={{
                __html: hero.description.childMarkdownRemark.html,
              }}
            />
          </ShowOnScroll>
        </Wrapper>
      </Section>

      <Section>
        <Wrapper width="normal">
          <div className="article-list--grid">
            {articles.length &&
              articles.map((a, i) => (
                <ShowOnScroll key={`article-${a.slug}`} delay={50 * i}>
                  {a.__typename === 'ContentfulGuidedProtocol' ? (
                    <ProtocolCard protocol={a} />
                  ) : (
                    <ArticleCard article={a} />
                  )}
                </ShowOnScroll>
              ))}
          </div>
        </Wrapper>
      </Section>

      {sections &&
        sections.map((section, idx) => {
          if (!section?.sectionType) {
            return null;
          }

          const componentData =
            articleListPageSectionsDefaultData[section.sectionType.slug] || {};

          return (
            <PageSection
              key={`${section.slug}_${idx}`}
              section={section}
              {...componentData}
            />
          );
        })}
    </StyledArticleListPage>
  );
};

interface ArticleListPageProps {
  hero: ContentfulPageSection;
  articles: (Article | Protocol)[];
  sections: ContentfulPageSection[];
}
