import styled from 'styled-components';
import { pxtorem } from '../../utils/tools';

export const StyledArticleListPage = styled.div`
  .article-list--text-banner {
    font-size: clamp(${pxtorem(40)}, 5vw, ${pxtorem(60)});
    line-height: 100%;
    margin: ${pxtorem(22)} 0 0;
    text-align: center;
    margin-inline: auto;

    p {
      margin: 0;
    }

    em {
      font-size: clamp(${pxtorem(34)}, 4.333vw, ${pxtorem(52)});
    }

    @media (min-width: 768px) {
      max-width: 60%;
    }

    @media (min-width: 1280px) {
      max-width: 50%;
    }
  }

  .article-list--grid {
    display: grid;

    > * {
      width: 100%;
    }

    place-content: center;
    place-items: top;
    grid-template-columns: minmax(360px, 1fr);
    gap: ${pxtorem(40)};

    justify-items: center;
    margin: ${pxtorem(40)} auto ${pxtorem(60)};

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, minmax(360px, 1fr));
      gap: ${pxtorem(80)} ${pxtorem(20)};
    }

    @media (min-width: 1280px) {
      grid-template-columns: repeat(3, minmax(380px, 1fr));
    }
  }
`;
